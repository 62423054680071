<template>
  <div class="MyToDoList">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-input label="事项" v-model="searchParams.taskContent" :maxlength="60" />
        <v-datepicker label="更新时间" type="rangedatetimer" :startTime.sync="searchParams.beginTime" :endTime.sync="searchParams.endTime" />
      </template>
      <template #operateSlot="scope">
        <v-button text="详情" type="text" @click="detail(scope.row)" />
        <v-button text="审批" type="text" @click="approval(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { queryListURL } from './api'

export default {
  name: 'MyToDoList',
  data () {
    return {
      searchUrl: queryListURL,
      searchParams: {
        taskContent: '',
        beginTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'taskContent',
          label: '事项'
        },
        {
          prop: 'createTime',
          label: '更新时间'
        }
      ]
    }
  },
  methods: {
    detail (row) {
      this.$router.push({
        name: 'customFormRecordForm',
        query: {
          formId: row.formId,
          id: row.businessId
        }
      })
    },
    approval (row) {
      this.$router.push({
        name: 'customFormRecordFormApproval',
        query: {
          processInstanceId: row.processInstanceId
        }
      })
    }
  }
}
</script>
